import axios from 'axios';
//import { makeRequest } from '../Utils/Request'
import { development } from  '../config'

const requestOptions = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };


export const loginCreator = (login) => {
    return {
        type:'LOGIN',
        login
    }
}

export const allCarListCreator = (allCarList) => {
    return {
        type:'VIEWALLCAR',
        allCarList
    }
}


export const activeVehicleActionCreator = (active) => {
    return {
        type:'ACTIVEVEHICLE',
        active
    }
}

export const deActiveVehicleActionCreator = (deActive) => {
    return {
        type:'DEACTIVEVEHICLE',
        deActive
    }
}

export const login = (username, password) => {
    let loginUrl = `${development}/api/user/yonetim/login`;

    const options = {
        url: loginUrl,
        method: 'POST',
        headers: {
          'Accept': requestOptions,
        },
        data: {
            username: username,
            password: password,
            id: 'brz'
        }
      };
      if(username === "bahadir.ulusoy"){
        return (dispatch) => {
            axios(options).then(res => {
            const loginDataParse = res.data;
            const loginData = loginDataParse;
            dispatch(loginCreator(loginData))
            },
            (err) => {
                console.log(err);
            })
            }
      }else{
          return false
      }
}

export const allCarListView = () => {
    let allCarListUrl = `${development}/api/allVehicleViews`;
    return (dispatch) => {
         axios.get(allCarListUrl).then((res) => {
                const allCarListData = res.data;
                dispatch(allCarListCreator(allCarListData))
                        },
                (err) => {
                    console.log(err);
                }
        )
    }
}

export const activeVehicle = () => {
    let ActiveVehicleUrl = `${development}/api/activeVehicle`;
    return (dispatch) => {
        axios.get(ActiveVehicleUrl).then((res) => {
                const ActiveVehicleUrlData = res.data;
                dispatch(activeVehicleActionCreator(ActiveVehicleUrlData))
            },
            (err) => {
                console.log(err);
            }
        )
    }
}

export const deActiveVehicle = () => {
    let deActiveVehicleUrl = `${development}/api/yonetim-sold-cars`;
    return (dispatch) => {
        axios.get(deActiveVehicleUrl).then((res) => {
                const deActiveVehicleUrlData = res.data;
                dispatch(deActiveVehicleActionCreator(deActiveVehicleUrlData))
            },
            (err) => {
                console.log(err);
            }
        )
    }
}