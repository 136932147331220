import styled from 'styled-components'

const HomeContainer = styled.div `
      padding: 0 0 20px 0;
`;

const HomeDesign = styled.div `
  .buttonContainer{
    display:flex;
    justify-content: center;
    margin-bottom: 15px;
      button{
        &:first-child{
          margin-right: 10px;
        }
      }
  }
`;

const ChartContainer = styled.div `
              display: flex;
            justify-content: space-around;
            margin: 20px 0;
            position: relative;
              div, canvas{
                width: 50%;
                max-width: 600px
              }
            @media screen and (max-width: 800px){
                display: none;
            }
`;

export { HomeContainer, HomeDesign, ChartContainer }