import React, { Component } from 'react'
import LoginComponent from '../../Components/Login/Loadable'
import Input from '../../Components/FormElements/Input/Loadable'
//import Button from '../../Components/FormElements/Button/Loadable'
import Logo from '../../Images/yonetim_logo.png'

import {connect} from 'react-redux';
let actions = require('../../Actions');

class Login extends Component {
    constructor(props){
        super(props);
        this.state={
            username: '',
            password: '',
            passwordType: false,
            loginStatus: null
        }
        this.inputTypeChange = this.inputTypeChange.bind(this)
        this.loginSubmit = this.loginSubmit.bind(this)
        this.loginInputChange = this.loginInputChange.bind(this)
    }
    
    componentDidUpdate(prevProps, prevState) {
        const { login } = this.props.borzMotor;
        if(login !== prevProps.borzMotor.login){
            if(login.status){
                localStorage.setItem('token', login.token)
                localStorage.setItem('username', login.username)
                window.location.reload();
            }else{
                this.setState({loginStatus: login})
            }
        }
    }
    
    
    loginSubmit(e){
        const {username, password} = this.state;
        this.props.login(username, password)
        e.preventDefault();
        
    }

    loginInputChange(e){
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        })
    }
    
    inputTypeChange(){
        const { passwordType } = this.state;
        this.setState({
            passwordType: !passwordType
        })
    }
    render() {
        const { passwordType, loginStatus } = this.state;
        return (
                <form 
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: '100%'
                }} 
                onSubmit={ this.loginSubmit }>
                        <div style={{ textAlign: 'center' }}>
                            <img style={{ width: '240px', marginBottom: '25px' }} src={Logo} alt="logo"/>
                        </div>
                    <LoginComponent>
                        <div className="userName">
                            <Input 
                                inputHtmlFor="username"
                                inputID="username"
                                name="username"
                                labelText="kullanıcı adı" 
                                type="text" 
                                onChange={ (e) => this.loginInputChange(e)}
                                required
                            />    
                        </div>
                        <div className="passwordGroup">
                            <span onClick={this.inputTypeChange} className="showHidePassword">{ passwordType ? 'Gizle' : 'Göster' }</span>
                            <Input 
                                labelText="şifre" 
                                inputHtmlFor="password"
                                inputID="password"
                                name="password"
                                type={ passwordType ? 'text' : 'password' } 
                                onChange={ (e) => this.loginInputChange(e)}
                                required
                                />    
                        </div>
                        <div className="buttonLogin">
                            <button type="submit">giriş yap</button>
                        </div>
                            { loginStatus && !loginStatus.status && 
                            <div className="rejectMessage">
                                {loginStatus.message}
                            </div>
                            }
                    </LoginComponent>
                </form>
        )
    }
}

export default connect((state)=>{return state},actions)(Login)
