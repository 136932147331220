const INITIAL_STATE = {
    login: {},
    allCarList: [],
    deActive: [],
    active: []
  };
  
  export default (state = INITIAL_STATE, action) => {
          switch (action.type) {   
            case 'LOGIN':
              {
                return {...state,login:action.login} 
              }
              case 'VIEWALLCAR':
                {
                  return {...state,
                    allCarList:action.allCarList,
                  } 
                }  
                case 'DEACTIVEVEHICLE':
                  {
                      return {...state,
                          deActive:action.deActive
                      }
                  }
                  case 'ACTIVEVEHICLE':
                    {
                        return {...state,
                            active:action.active,
                        }
                    }
          default :
          return {...state};    
      }
    };