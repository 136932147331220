import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { HomeContainer, HomeDesign, ChartContainer } from "./style";
import Collapse from "../../Components/Collapse/Loadable";
import { connect } from "react-redux";
import Button from "../../Components/FormElements/Button/Loadable";
import { activeVehicle, deActiveVehicle, allCarListView } from "../../Actions";
import { arrayUnique } from "../../Utils/arrayUnique";
import moment from "moment";
import Chart from "../../Components/Chart/Loadable";

class Home extends Component {
  state = {
    selectYear: "2021",
    yearInCars: [],
    monthNames: [],
    totalYears: [],
    totalDataHeading: [
      "satılan toplam araç adeti",
      "alış fiyatları toplamı",
      "satış fiyatları toplamı",
      "masraflar toplamı",
      "toplam kar",
    ],
    allPureData: [],
  };

  componentDidMount() {
    this.props.activeVehicle();
    this.props.deActiveVehicle();
    this.props.allCarListView();
  }

  componentDidUpdate(prevProps, prevState) {
    const { deActive } = this.props.borzMotor;
    if (prevProps.borzMotor.deActive !== deActive) {
      this.setState({ allPureData: deActive }, () =>
        this.currentMonthOfYears()
      );
    }
  }

  currentMonthOfYears() {
    const { allPureData, selectYear } = this.state;
    const totalMonth = allPureData
      .filter(
        (year) =>
          year.sellingDate &&
          selectYear === moment(year.sellingDate).format("YYYY")
      )
      .map((e) => moment(e.sellingDate).format("YYYY-MM"));
    totalMonth &&
      totalMonth.length > 0 &&
      this.setState({ monthNames: arrayUnique(totalMonth) }, () => {
        this.totalYears();
        this.selectYearOfCars();
      });
  }

  selectYearOfCars() {
    const { allPureData, selectYear } = this.state;
    const currentCars = allPureData
      .filter(
        (year) =>
          year.sellingDate &&
          selectYear === moment(year.sellingDate).format("YYYY")
      )
      .map((e) => e);
    currentCars &&
      currentCars.length > 0 &&
      this.setState({ yearInCars: currentCars });
      console.log("this.state.yearInCars");
      console.log(this.state.yearInCars);
      console.log("this.state.yearInCars");
  }

  totalYears() {
    const { allPureData } = this.state;
    const totalYear = allPureData
      .filter(
        (year) => year.sellingDate && moment(year.sellingDate).format("YYYY")
      )
      .map((e) => moment(e.sellingDate).format("YYYY"));
    totalYear &&
      totalYear.length > 0 &&
      this.setState({ totalYears: arrayUnique(totalYear) });
  }

  monthNameConvert(month) {
    const monthLocale = moment().locale("tr");
    return monthLocale.localeData().months(moment(month));
  }

  selectedYear = (e) => {
    const { deActive } = this.props.borzMotor;
    const select = e.currentTarget.innerText;
    const currentCars = deActive
      .filter(
        (year) =>
          year.sellingDate && select === moment(year.sellingDate).format("YYYY")
      )
      .map((e) => e);

    currentCars &&
      currentCars.length > 0 &&
      this.setState(
        {
          yearInCars: currentCars,
          selectYear: select,
        },
        () => this.currentMonthOfYears()
      );
  };

  render() {
    const { monthNames, yearInCars, selectYear, totalYears } = this.state;
    const { active, deActive, allCarList } = this.props.borzMotor;

    const carLength = {
      labels: ["Satışta Olan Araç", "Toplam Araç"],
      datasets: [
        {
          data: [active && active.length, allCarList && allCarList.length],
          backgroundColor: ["#660116", "#b3b3b3"],
          borderColor: ["#2b2b2b", "#2b2b2b"],
          hoverBackgroundColor: ["#660116", "#b3b3b3"],
        },
      ],
    };
    const carLength2 = {
      labels: ["Satılan Araç", "Toplam Araç"],
      datasets: [
        {
          data: [deActive && deActive.length, allCarList && allCarList.length],
          backgroundColor: ["#660116", "#b3b3b3"],
          borderColor: ["#2b2b2b", "#2b2b2b"],
          hoverBackgroundColor: ["#660116", "#b3b3b3"],
        },
      ],
    };
    return (
      <HomeContainer>
        <Helmet>
          <title> 🦅 | Borz Motor Yönetim</title>
        </Helmet>
        <ChartContainer>
          <Chart
            data={carLength}
            width={100}
            height={50}
            options={{ maintainAspectRatio: false }}
          />
          <Chart
            width={100}
            height={50}
            data={carLength2}
            options={{ maintainAspectRatio: false }}
          />
        </ChartContainer>
        <HomeDesign>
          <div className="buttonContainer">
            {totalYears &&
              totalYears.map((y, i) => (
                <Button
                  type="dashed"
                  label={y}
                  click={this.selectedYear}
                  key={i}
                />
              ))}
          </div>
          <div className="collapseContainer">
            {monthNames && monthNames.length > 0 && (
              <Collapse
                year={selectYear}
                cars={yearInCars}
                month={monthNames}
              />
            )}
          </div>
        </HomeDesign>
      </HomeContainer>
    );
  }
}

export default connect(
  (state) => {
    return state;
  },
  { activeVehicle, deActiveVehicle, allCarListView }
)(Home);
